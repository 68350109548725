if (in_list(window.location.pathname.split('/'),'blog')) {
    console.log('blog')
    $("main.my-4").addClass('bg-custom')
}
else if (in_list(window.location.pathname.split('/'),'newsletters')) {
    console.log('newsletter')
    $("main.my-4").addClass('bg-custom')
    $("[data-doctype=Newsletter]").addClass('bg-custom')
    $('.foot').css("margin-top","0")
}
else {
    console.log('empty')
    $("main.my-4").removeClass('bg-custom')
}